<div class="chat-step-wrapper" *ngIf="step && flowDesign">
  <div
    class="text-help"
    style="padding-left: 84px; font-size: 12px; margin-bottom: 5px"
    [@fadeIn]
  >
    {{ flowDesign.bot_name }}
  </div>
  <div class="agent-messages-wrapper" style="display: flex; position: relative">
    <div
      #agentWrapper
      [ngStyle]="{
        transform: 'translateY(' + offsetY + 'px)',
        transition: 'transform 0.4s ease-in'
      }"
    >
      <img
        class="agent-icon"
        [@scale]
        [src]="flowDesign.avatars.selected"
        height="56px"
        width="56px"
      />
      <loading-dots *ngIf="!allAgentMessagesDisplayed"></loading-dots>
    </div>
    <div style="display: flex; flex-direction: column" #messagesContainer>
      <div *ngFor="let message of displayedMessages; trackBy: trackByFn">
        <ng-container *ngIf="message && currentLang">
          <agent-message-field
            [message]="message.message[currentLang]"
          ></agent-message-field>
        </ng-container>
      </div>
    </div>
  </div>

  <user-message-field
    *ngIf="userMessage"
    [userMessage]="userMessage"
  ></user-message-field>

  <!-- [stepForm]="stepForm" -->
  <user-options-buttons
    *ngIf="allAgentMessagesDisplayed && currentLang"
    [step]="step"
    [currentLang]="currentLang"
    (buttonClicked)="setNextStep($event)"
  >
    <!-- IF INPUT TYPE === TEXTAREA -->
    <!-- [stepType]="'feedback'" -->
    <ng-container *ngIf="step.type === 'FEEDBACK'">
      <div style="padding: 0px 15px">
        <user-input-area
          *ngIf="step.additional_config?.feedback_type === 'textarea'"
          (feedbackTextArea)="onFeedbackTextArea($event)"
          [placeholderText]="'Enter text here'"
        ></user-input-area>
        <ng-container
          *ngIf="step.additional_config?.feedback_type === 'checkbox'"
        >
          <div class="text-help" style="font-size: 14px">Select options</div>
          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin: 10px 0px;
              background-color: #fff;
              padding: 15px;
              border-radius: 5px;
            "
          >
            <ng-container
              *ngFor="let option of step.additional_config.checkbox_options"
            >
              <mat-checkbox
                class="feedback-checkbox-option"
                [checked]="option.checked"
                (change)="onCheckboxChange($event, option)"
                >{{ option[currentLang] }}</mat-checkbox
              >
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- IF STEP TYPE === "contact_form" -->
    <ng-container *ngIf="step.type === 'CONTACT_FORM'">
      <div style="padding: 0px 15px">
        <user-input-contact-fields
          [inputs]="step.additional_config.contact_fields"
          (inputValue)="onContactFieldValueChange($event)"
          [currentLang]="currentLang"
        ></user-input-contact-fields>
      </div>
    </ng-container>
  </user-options-buttons>
</div>
