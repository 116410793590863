<!-- <div class="poisition-relative"> -->
<!-- background: #fff; -->
<!-- <div
    class="snippet"
    data-title="dot-flashing"
    style="
      position: absolute;
      top: 0;
      left: 0;
      height: auto;
      width: auto;
      display: flex;
    "
  >
    <div class="stage" style="margin: auto"> -->
<div class="dot-flashing" style=""></div>
<!-- </div>
  </div>
</div> -->
