<ng-container *ngIf="flowDesign && chatFlow">
  <div
    class="app-container-chatbot"
    style="height: 100%"
    [style.font-family]="flowDesign.typography.font_family"
  >
    <div
      class="app-header"
      [style.background-color]="flowDesign.header_background_color"
    >
      <div class="app-header-content">
        <div class="comp-logo" style="display: flex; align-items: center">
          <a [href]="flowDesign.company_url" target="_blank">
            <img [src]="flowDesign.company_logo_img_url" style="height: 32px" />
          </a>
        </div>
        <div class="">
          <!-- *ngIf="chatFlow.language.available_languages.length > 1" -->
          <language-switcher
            *ngIf="chatFlow && funnelLanguage"
            [selectedLanguage]="funnelLanguage"
            [languageObject]="chatFlow.language"
            (currentLanguageChanged)="onLanguageChanged($event)"
          ></language-switcher>
        </div>
      </div>
    </div>
    <div #scrollContainer class="chat-flow-component" *ngIf="steps">
      <!-- STEP COMPONENT -->
      <div
        class="steps-wrapper full-width"
        *ngIf="steps"
        style="margin-bottom: 70px"
      >
        <ng-container *ngIf="steps && funnelLanguage">
          <ng-container *ngFor="let step of steps">
            <chat-step
              [step]="step"
              [flowDesign]="flowDesign"
              (scrollToBottom)="scrollToBottom()"
              [currentLang]="funnelLanguage"
              [testMode]="testMode"
            ></chat-step>
          </ng-container>
        </ng-container>
      </div>
      <!-- // STEP COMPONENT -->
    </div>
  </div>
  <div style="position: relative">
    <powered-by-hypt></powered-by-hypt>
  </div>
</ng-container>
