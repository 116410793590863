import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  flowDesign,
  Step,
  StepAction,
  StepMessage,
} from '../../interfaces/chat-flow.interface';
import { ScrollService } from '../../scroll.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { MESSAGE_DELAY } from 'src/app/shared/constants/global.constants';
import { ChatBotFlowSaService } from '../ui-chat-bot-sa/chat-bot-flow-sa.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'chat-step',
  templateUrl: './chat-step.component.html',
  styleUrls: ['./chat-step.component.scss'],
  // encapsulation: ViewEncapsulation.None,

  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
    trigger('scale', [
      transition(':enter', [
        style({
          // transform: 'scale(0.6)',
          opacity: 0,
        }),
        // animate('400ms ease-out', style({ transform: 'scale(1)', opacity: 1 })),
        animate(
          `${MESSAGE_DELAY - 200}ms ease-in`,
          style({
            // transform: 'scale(1) ',
            opacity: 1,
          })
        ),
      ]),
    ]),
  ],
})
export class ChatStepComponent implements OnInit {
  @ViewChild('agentWrapper') agentIcon!: ElementRef;
  // @ViewChild('agentIcon') agentIcon!: ElementRef;
  @ViewChild('messagesContainer') messagesContainer!: ElementRef;
  offsetY: number = 0;
  previousHeight: number = 0;
  @Output()
  scrollToBottom: EventEmitter<void> = new EventEmitter<void>();
  @Input() step: Step | null = null;
  @Input() flowDesign: flowDesign | null = null;
  @Input() currentLang!: string;
  @Input() testMode?: boolean;
  fadeInState: string = 'hidden';
  userMessage: string = '';
  displayedMessages: StepMessage[] = [];
  allAgentMessagesDisplayed: boolean = false;
  inputValues: Record<string, string> = {};
  feedback: string = '';

  constructor(
    private chatBotFlowSaService: ChatBotFlowSaService,
    private scrollService: ScrollService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // console.log('chat-step', this.step);
    // this can be moved in to component that handles logic of checkboxes
    if (this.step && this.step.type === 'FEEDBACK') {
      this.step.additional_config.checkbox_options?.forEach((option) => {
        option.checked = false; // Initialize all as unchecked
      });
    }
  }

  ngAfterViewInit(): void {
    if (
      this.messagesContainer &&
      this.messagesContainer.nativeElement &&
      !this.allAgentMessagesDisplayed
    ) {
      this.previousHeight = this.messagesContainer.nativeElement.offsetHeight;
      this.showMessagesWithDelay();
    }
  }

  showMessagesWithDelay() {
    let index = 0;
    const addNextMessage = () => {
      if (this.step && index < this.step.messages.length) {
        const message = this.step.messages[index];
        if (message && message.message) {
          this.displayedMessages.push(message); // if you want to access the first object
        }
        index++;
        // this trigger transition immediately even if message hight is larger then 2 lines
        setTimeout(() => {
          this.updateIconPosition();
        });
        setTimeout(() => {
          this.scrollService.scrollToBottom();
        }, 100);
        setTimeout(() => {
          // After the last message
          if (this.step && index >= this.step.messages.length) {
            setTimeout(() => {
              this.allAgentMessagesDisplayed = true;
            }, MESSAGE_DELAY);
          } else {
            // If there are still messages left, set a new timeout for the next one
            setTimeout(addNextMessage, MESSAGE_DELAY);
          }
          this.cdr.detectChanges();
        }, MESSAGE_DELAY);
      }
    };

    addNextMessage();
  }

  // triggers on any button click from user
  setNextStep(option: StepAction) {
    console.log('option', option);
    // Triggers API call if step has API config
    if (this.step) {
      if (this.step.api && !this.testMode) {
        this.step.api.data.condition_value = option.condition_value;
        // set API conditions here`
        this.chatBotFlowSaService
          .updateFeedbackApi(this.step.api)
          .subscribe((response) => {
            console.log('response', response);
          });
        // Opens a new tab if redirect is present
        if (option.action.redirect) {
          let optionLink: string;

          if (this.step.type === 'GOOGLE_REVIEW') {
            // Directly assign the redirect link for GOOGLE_REVIEW type
            optionLink = option.action.redirect as string;
          } else if (
            typeof option.action.redirect === 'object' &&
            option.action.redirect !== null
          ) {
            // Use the language key selector for other types
            optionLink = option.action.redirect[this.currentLang];
          } else {
            // Directly assign the redirect link if it's a string
            optionLink = option.action.redirect;
          }

          window.open(optionLink, '_blank');
        }
        // if (this.step.type === 'CONTACT_FORM') {
        // }
      }
    }
    setTimeout(() => {
      this.chatBotFlowSaService.addStepToSortedStepsById(option.action.jump);
      // How long it will take after user selects option to show next step
    }, MESSAGE_DELAY + 400);

    this.userMessage = option.text[this.currentLang];
    // }
  }
  onFeedbackTextArea(value: string) {
    // console.log('feedbackTextArea', value);
    // Set feedback text value to the API data
    if (this.step && this.step.type === 'FEEDBACK') {
      // this.step.api.data.feedback = value;
      // this.step.api.data.feedback.text = value;
      // console.log('this.step.api.data', this.feedback);
      this.step.api.data.feedback.text = value;
      // this.feedback = value;
    }
  }
  trackByFn(index: number, item: any): any {
    return item?.id || index;
  }

  updateIconPosition() {
    setTimeout(() => {
      const currentHeight = this.messagesContainer.nativeElement.offsetHeight;
      const heightDifference = currentHeight - this.previousHeight;
      this.offsetY += heightDifference;
      this.previousHeight = currentHeight;
    });
    // this.cdr.detectChanges();
  }
  onContactFieldValueChange(event: { key: string; value: string }): void {
    if (this.step && this.step.type === 'CONTACT_FORM') {
      console.log('newValues', event);
      this.inputValues[event.key] = event.value;
      this.step.api.data = { ...this.step.api.data, ...this.inputValues };
    }
  }
  onCheckboxChange(
    event: MatCheckboxChange,
    option: { checked?: boolean; [key: string]: any }
  ): void {
    if (this.step?.type === 'FEEDBACK') {
      const isChecked = event.checked;
      // Update your option object or handle the change as needed
      option.checked = isChecked;
      if (option.checked) {
        // Push the item if checked
        this.step?.api.data.feedback.categories?.push(option[this.currentLang]);
      } else if (!option.checked) {
        // Remove the item if unchecked
        const categories = this.step?.api.data.feedback.categories;
        const index = categories?.indexOf(option[this.currentLang]);
        if (index !== -1) {
          categories?.splice(index!, 1); // Using the non-null assertion operator
        }
      }
    }
    // this.step;
    // "feedback": {"categories": []}
  }
}
