<div
  style="display: flex; margin-top: 15px; justify-content: center; width: 100%"
>
  <div
    class="text-accent shadow-lg powered-by-hypt"
    style="
      font-size: 14px;
      text-align: center;
      padding: 10px 20px;
      background-color: #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      box-shadow: 0 0 10px #8c8c8c54;
    "
  >
    <!-- <i>solution powered by<b class="ms-1"> hypt.</b></i> -->
    <div style="display: flex" [innerHTML]="poweredByHypt"></div>
  </div>
</div>
