import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import en_GB from '../translations/en_GB';
import de_DE from '../translations/de_DE';
import fr_FR from '../translations/de_DE';
import it_IT from '../translations/de_DE';
type Lang = 'en_GB' | 'de_DE' | 'fr_FR' | 'it_IT';
// Define a mapping from language codes to their respective translation objects
const TRANSLATIONS = {
  en_GB: en_GB,
  de_DE: de_DE,
  fr_FR: fr_FR,
  it_IT: it_IT,
  // Add other translations here
};

class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: Lang): Observable<any> {
    // Return the translation object for the requested language
    const translation = TRANSLATIONS[lang];
    if (!translation) {
      console.warn(`Translation for language "${lang}" not found.`);
      return of({}); // Return an empty object if the translation is not found
    }
    return of(translation);
  }
}

// Use this function to provide the custom loader
export function CustomLoaderFactory() {
  return new CustomTranslateLoader();
}
