export default {
  choose_an_option: 'Choose an option:',
  en_GB: 'English',
  de_DE: 'German',
  fr_FR: 'French',
  it_IT: 'Italian',
  redirect_counter_message:
    "I'll redirect you in {{counter}} seconds to our website",
  whatsapp: 'WhatsApp',
  sms: 'SMS /iMessage',
  email: 'E-Mail',
};
