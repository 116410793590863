import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ApiConfig,
  ChatFlow,
  Step,
} from '../../interfaces/chat-flow.interface';
import { ApiHttpService } from 'src/app/shared/services/api-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatBotFlowSaService {
  constructor(private apiHttp: ApiHttpService) {}

  // chat flow steps that can be removed later on when we have a backend
  private chatFlowStepsSubject = new BehaviorSubject<Step[]>([]);
  // tracking of user steps && flow config for SA
  private chatFlowSubject = new BehaviorSubject<ChatFlow | null>(null);
  chatFlow$ = this.chatFlowSubject.asObservable();
  // chat flow steps that can be removed later on when we have a backend
  private userStepsSubject = new BehaviorSubject<Step[]>([]);
  userSteps$ = this.userStepsSubject.asObservable();

  // remove step visibility parameter
  addStepToSortedStepsById(id: number): void {
    // console.log('addStepToSortedStepsById', id);
    const currentSteps = this.chatFlowStepsSubject.getValue();
    // console.log('currentSteps', currentSteps);
    const currentSortedSteps = this.userStepsSubject.getValue();
    const stepToAdd = currentSteps.find((step) => step.step === id);
    // console.log('stepToAdd', stepToAdd);
    if (stepToAdd) {
      this.userStepsSubject.next([...currentSortedSteps, stepToAdd]);
      // for new component
      const previousVal = this.chatFlowSubject.value;
      if (previousVal) {
        // console.log('previousVal', previousVal);
        // Create a new object with the updated steps array
        const newVal = {
          ...previousVal, // Spread to copy properties of previousVal
          steps: [...previousVal.steps, stepToAdd], // Create a new array with the new step added
        };
        // Emit the new value
        this.chatFlowSubject.next(newVal);
      }
    }
  }

  setFlowConfig(config: ChatFlow): void {
    // console.log('configList', config);
    this.chatFlowStepsSubject.next(config.steps);
    const flow = { ...config, steps: [...config.steps] };
    // flow.steps = flow.steps.filter((step) => step.step === 1);
    console.log('flow.steps', flow.steps);
    this.userStepsSubject.next([config.steps[0]]);
    // console.log('this.userStepsSubject', [config.steps[0]]);
    this.chatFlowSubject.next(flow);
  }

  updateFeedbackApi<T>(config: ApiConfig<T>): Observable<any> {
    // mimic call to API
    // return new Observable((observer) => {
    //   setTimeout(() => {
    //     observer.next({
    //       method: config.method,
    //       url: config.url,
    //       body: config.data,
    //     });
    //     observer.complete();
    //   }, 100); // Simulate async operation delay
    // });
    // console.log('config', config);
    // Create a URL object from the config.url
    const urlObj = new URL(config.url);

    // Extract the path and query from the URL
    const pathWithQuery =
      environment.apiServers.apiEndpointFeedback +
      urlObj.pathname +
      urlObj.search;
    console.log('pathWithQuery', pathWithQuery);

    if (config.method.toUpperCase() === 'POST') {
      // console.log('POST', pathWithQuery, config.data);
      return this.apiHttp.post<any>(pathWithQuery, config.data);
    } else if (config.method === 'PATCH') {
      return this.apiHttp.patch<any>(pathWithQuery, config.data);
    } else {
      // Optionally handle unsupported methods
      throw new Error(`Unsupported method: ${config.method}`);
    }
  }
}
