import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Step,
  StepAction,
} from 'src/app/features/chat-bot/interfaces/chat-flow.interface';
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
import { ScrollService } from '../../scroll.service';
import { MESSAGE_DELAY } from 'src/app/shared/constants/global.constants';

@Component({
  selector: 'user-options-buttons',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.scss'],

  // encapsulation: ViewEncapsulation.None,

  animations: [
    trigger('zoomIn', [
      transition(':enter', [
        style({ transform: 'scale(0.6)', opacity: 0 }), // start state
        animate(
          '{{delay}}ms ease-in',
          style({ transform: 'scale(1)', opacity: 1 })
        ), // end state
      ]),
    ]),
    trigger('collapse', [
      state(
        'initial',
        style({
          opacity: 0,
        })
      ),
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0px',
          opacity: 0,
        })
      ),
      transition('initial => open', [animate(`0ms ease-in`)]),
      transition('open => closed', [animate(`${MESSAGE_DELAY}ms ease-out`)]),
      transition('closed => open', [animate(`${MESSAGE_DELAY}ms ease-in`)]),
      transition('initial => closed', [animate(`${MESSAGE_DELAY}ms ease-out`)]),
    ]),
  ],
})
export class UserOptionsComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter<StepAction>();
  @Input() text: string = '';
  @Input() icon: string = '';
  @Input() step: Step | null = null;
  @Input() currentLang!: string;
  counter: number = 5; // Initialize counter for goodbye step
  alignCenter: boolean = false;
  inlineButtons: boolean = false;
  options: StepAction[] = [];
  public animationState = 'initial';
  optionSelected: boolean = false;
  constructor(
    private scrollService: ScrollService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.scrollService.scrollToBottom();
      this.animationState = 'open';
      this.cdr.detectChanges();
    }, 100);

    if (this.step) {
      this.options = this.step.actions;
      if (this.step.type === 'GOODBYE' && this.options.length === 1) {
        this.startCountdown(this.step.actions[0].action.link!);
        return;
      }
      if (this.options.length === 1) {
        const option = this.options[0];
        if (!option.text[this.currentLang]?.trim()) {
          setTimeout(() => {
            this.onClick(option);
          });
        }
      }

      // this.options.forEach((option) => {
      //   if (
      //     !option.text ||
      //     !option.text[this.currentLang] ||
      //     option.text[this.currentLang].trim() === ''
      //   ) {
      //     setTimeout(() => {
      //       this.onClick(option);
      //     });

      //   }
      // });

      this.alignCenter =
        this.step.type === 'FEEDBACK' || this.options.length === 1;
    }
  }
  onClick(option: StepAction) {
    this.buttonClicked.emit(option);
    this.optionSelected = true;
    this.animationState = 'closed';
  }
  // Goodbye step redirect to link logic
  startCountdown(link: string) {
    const intervalId = setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        clearInterval(intervalId); // Stop the countdown
        window.location.href = link;
      }
    }, 1000);
  }
}
